.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiDialogContent-root{
  display: flex;
  flex-direction: column;
}

.makeStyles-root-5 {
  & div {
    width: fit-content !important;
  }
}
