table {
  width: 100% !important;
}

.ant-notification {
  animation: fromTop 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0 !important;
}
.ant-select-selection-search{
  width: 100% !important;
}
.ant-select-selection-search{
  left: 0 !important;
  right: 0 !important;
  padding: 0 0.5rem !important;
  & input{
  }
}

.ant-alert-message {
  margin: 0 1rem;
  font-size: 15px !important;
}

input.ant-select-selection-search-input {
  height: 100%;
}

//to center placeholder of antd dropdown placeholder
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

@keyframes fromTop {
  from {
    transform: translateY(-100);
  }
  to {
    transform: translateY(0);
  }
}

.ant-notification-notice-message {
  font-weight: 500;
}

.ant-table {
  overflow-y: hidden;
  overflow-x: auto;

  & .ant-table-cell {
    white-space: pre;
  }

  & .ant-table-cell:not(:first-child) {
    padding: 1rem 1.5rem !important;
  }
  //   & .ant-table-cell {
  //     padding: 0.5rem 1.5rem !important;
  //   }

  & .ant-table-thead {
    padding-bottom: 1rem;
    border-bottom: 1px solid #f0f0f0;
    & .ant-table-cell {
      font-size: inherit;
    }
  }
  & .ant-table-tbody {
    margin-top: 1rem !important;
    & .ant-table-cell {
      // font-size: inherit !important;
    }
  }

  & .ant-table-row {
    &:hover {
      // background-color: #f4f4f4;
    }
  }

  ::-webkit-scrollbar {
    // display: none;
    background-color: #fff;
    margin: 5px 0;
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: $main-bg-color;
    border-radius: 5px;
    /* color of the tracking area */
    // padding: 5rem;
    margin-top: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd; /* color of the scroll thumb */
    height: 5px;
    border-radius: 7px;
    margin: 5rem;
    /* roundness of the scroll thumb */
  }
}
