$nav-item-color: #b27362;
$gheeed-green: #3a7351;
$gheeed-light-green: #36B37E;
$banner-sub-title: #5a5a5a;
$disabled-item-gray: #8e8e8e;
$gray-border: #b2b2b2;
$main-bg-color: #f8f8f8;
$light-grey: #c8c8c8;
$light-grey-1: #F3F3F3;
$light-border-grey: #e4e6ef;
$circle-background: #a8aea9;
$carsoule-background: #ced9dc;
$carsoule-title: #7a9b80;
$carsoule-description: #6d6d6d;
$button-color: #404040;
$product-view-bg: #cfd4ce;
$dark-text: #404040;
$dark-text: #404040;
$light-dark-text: #474747;
$product-price: #b37362;
$gheeed-color-light: #d0a099;
$cart-title: #282828;
$product-bg: #ebebeb;
$danger-color: #dc3545;
$warning: #FFFBE6;
$blue-link: #0052CC;
$green-retrive: #727C7D;
$orange-retrive: #D7B88A;

