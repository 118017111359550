@import "/src/constants/colors.scss";

.splash-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-size: 4rem;
  // animation: opacity-anim 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}


.gheed-text-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10px;
  & svg {
    width: 14vw !important;
    height: auto;
    max-height: 140px;
    overflow: hidden;
    @media (max-width: 700px) {
      // transform: scale(0.9);
    }
  }

  @media (max-width: 700px) {
    // transform: scale(0.8);
    margin-top: -7px;

    & img {
      width: 120px !important;
    }
    & .logo-down-text {
      // font-size: 16px;
    }
  }
}

.logo-down-text {
  bottom: 19%;
  left: 50%;
  // font-size: 21px;
  font-weight: 600;
  transform: translate(-50%, 0%);
  color: $nav-item-color;
  font-family: "Almarai", sans-serif !important;
}
