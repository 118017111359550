@import "/src/constants/colors.scss";
@import "/src/constants/diemnsions.scss";

@mixin min-screen($width,$class) {
  @media (min-width:$width+'px') {
    // @include #{$class};
  }
}
.bottom-0{
  bottom: 0;
}

.absolute-down-center{
  transform: translate(-50%,100%);
  left: 50%;
}

.mb-6{
  margin-bottom: 2rem !important;
}
.mb-9{
  margin-bottom: 2.5rem !important;
}
.white-space-pre{
  white-space: pre;
}

.link-like{
  color: $blue-link;
  cursor: pointer;
  text-decoration: underline;
}

.line-throught{
  text-decoration: line-through;
}
.gray-font{
  color: $disabled-item-gray !important;
}
.fw-light{
  font-weight: 400 !important;
}

.fw-bold{
  font-weight: bold !important;
}

.op-sm{
  opacity: 0.3;
}
.op-md{
  opacity: 0.5;
}
.op-lg{
  opacity: 0.7;
}
.op-xlg{
  opacity: 0.9;
}

.fw-bolder{
  font-weight: bolder !important;
}

.gap-2-elem{
  & > *{
    margin-right: 0.5rem;
  }
}

.font-md-sm{
  // @include min-screen(600);
}

.font-xsm{
  font-size: 12px;
}
.font-sm{
  font-size: 15px;
  // @include min-screen(600,'.font-lg');
}
.font-md{
  font-size: 17px;
}
.font-lg{
  font-size: 21px;
}
.font-xlg{
  font-size: 24px;
}
.align-self-start{
  align-self: flex-start !important;
}
.elem-space-sm {
  & > *:not(:first-child) {
    margin: 0 0.3rem;
  }
}

.fit-content {
  width: fit-content !important;

  & * {
    // width: fit-content !important;
  }
}

.ant-empty-description {
  text-align: center !important;
}
.ant-empty-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-empty {
  display: flex;
  flex-direction: column;
}

.al-center-just-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-select {
  &::selection {
    background-color: inherit !important;
    color: inherit !important;
  }
}

.w-lg-100 {
  @media (min-width: 1200px) {
    width: 100% !important;
  }
}

.w-sm-100 {
  @media (min-width: 768px) {
    width: 100% !important;
  }
}

.white-space-pre {
  white-space: pre;
}

.w-md-100 {
  @media (min-width: 992px) {
    width: 100% !important;
  }
}

.gap-xs {
  gap: 0.5rem;
}

.pointer {
  cursor: pointer !important;
}
.flex-1 {
  flex: 1;
}

.text-gray {
  color: $banner-sub-title;
}

.font-600 {
  font-weight: 600;
}

.gap-sm {
  gap: 1rem !important;
}
.p-0-mobile {
  @media (max-width: 700px) {
    padding: 0 !important;
  }
}
.gap-xsm {
  gap: 6px !important;
}

.gap-md {
  gap: 2rem !important;
}

.gap-lg {
  gap: 3rem !important;
}

@media (min-width: 1200px) {
  .w-lg-50 {
    width: 50% !important;
  }
  .mx-lg-0 {
    margin: 0 !important;
  }
}

@media (max-width: 700px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .font-lg{
    font-size: 17px;
  }
}

.mt-10 {
  margin-top: 5rem;
}

.font-sm {
  font-size: 12px;
}

.w-md-20 {
  @media (min-width: 800px) {
    width: 20% !important;
  }
}

.w-lg-10 {
  @media (min-width: 1250px) {
    width: 10% !important;
  }
}

.hSaKRS {
  display: none !important;
}

.cOWINi {
  margin: 0 !important;
}

.font-bold {
  font-weight: bold;
}

.ant-table {
  display: table;
  width: 100%;
  max-width:100%;
  overflow-x:auto;
  & .ant-table-row {
    &:hover {
      // background-color: inherit !important;
    }
  }
}

.color-white {
  color: #fff !important;
}

.text-center {
  text-align: center !important;

  & * {
    text-align: center !important;
  }
}

.label-light-success {
  color: #1bc5bd;
  background-color: #c9f7f5;
}

.label-light-warning {
  color: #ffa800;
  background-color: #fff4de;
}

.label-light-danger {
  color: #f64e60;
  background-color: #ffe2e5;
}

.label-inline.label-sm {
  padding: 0.3rem 0.8rem !important;
}

.label-inline {
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}

.show {
  & > .btn-primary.dropdown-toggle {
    background-color: inherit !important;
    border-color: inherit !important;
    outline: none !important;
  }
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
}

.btn-primary {
  background-color: inherit !important;
  border-color: inherit !important;
  outline: none !important;
  box-shadow: none !important;

  & :hover {
    background-color: inherit !important;
    border-color: inherit !important;
    outline: none !important;
  }

  & :focus {
    background-color: inherit !important;
    border-color: inherit !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
