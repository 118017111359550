@import "~bootstrap/scss/bootstrap";
@import "/src/constants/colors.scss";
@import "/src/constants/diemnsions.scss";
@import "/src/constants/custome-bootstrap.scss";
@import "/src/constants/custom-material-ui.scss";
@import "/src/app/styles/antd.scss";
@import "/src/app/styles/carsoul.scss";

*::selection {
  // background-color: inherit !important;
  // color: inherit !important;
}

.ltr-direction {
  direction: ltr !important;
}

.d-grid {
  display: grid;
}

.gheeed-color {
  color: $nav-item-color !important;
  & * {
    color: $nav-item-color !important;
  }
}

.cukhLH {
  margin: 0 !important;
}
.bkVSTc {
  display: none !important;
}

.sc-iBPRYJ,
.cOWINi .rec .rec-pagination {
  display: none !important;
  margin: 0 !important;
}

.rec-slider-container {
  margin: 0 !important;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media (max-width: $sm+"px") {
    grid-template-columns: 1fr;
  }
}

.rtl-font {
  font-family: "Almarai", sans-serif !important;
  & *,
  button,
  input,
  form {
    font-family: "Almarai", sans-serif !important;
  }
}

.ltr {
  & * {
    font-family: "Poppins", sans-serif !important;
    direction: ltr !important;
    text-align: left !important;
  }

  & .text-center {
    text-align: center !important;
  }
}

.back-drop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.267);
}

iframe {
  border: none !important;
  height: fit-content !important;
}

.intercom-namespace .intercom-1iioky3 {
  height: 60px !important;
}

.intercom-namespace .intercom-1d87wj3 > iframe{
  min-height: 100% !important;
}

* {
  padding: 0;
  outline: none !important;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  // margin-bottom: 0 !important;
}
.grid-gap-md-col {
  grid-column-gap: 1.5rem !important;
}

.base-padding {
  padding: 0 7%;
}

.no-base-padding-sm {
  @media (max-width: 700px) {
    padding: 0 !important;
  }
}

.base-mobile-padding {
  @media (max-width: 700px) {
    padding: 0 7% !important;
  }
}

.home-wrapper {
  padding: 0 7%;
  overflow-x: hidden !important;
  // overflow-y: hidden;
}

html,
body,
#root {
  height: 100%;
  padding-right: 0 !important;
  background-color: $main-bg-color;
  scroll-behavior: smooth;
}

.ltr-font{
  font-family: "Almarai", sans-serif !important;
  & *{
    font-family: "Almarai", sans-serif !important;
  }
}

.en-font{
  font-family: "Poppins", sans-serif !important;
}

.force-rtl {
  direction: rtl !important;
}
.force-ltr {
  direction: ltr !important;
  text-align: left !important;
}

.force-ltr {
  direction: ltr !important;
}

.text-greeen{
  color: $carsoule-title;
}

.text-gheeed{
  color: $nav-item-color;
}


.rtl *:not(input.form-control) {
  direction: rtl;
  text-align: right;
  // font-family: "Cairo", sans-serif !important;
  font-family: "Almarai", sans-serif;

  .prevent-rtl {
    & * {
      direction: rtl !important;
      text-align: left !important;
    }
  }

  & .social-btns {
    left: 11%;
    right: auto;
  }

  & .react-tel-input {
    direction: ltr !important;
    text-align: left !important;
  }

  ul[role="listbox"] {
    left: 0 !important;

    & li {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
  }
}

.flex-root {
  flex: 1;
}

.white-opacity {
  height: 182px;
  background-color: #fff;
  opacity: 0.25;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

a {
  color: inherit !important;

  &:hover {
    text-decoration: none;
  }
}

.scroll-x-container {
  display: flex;
  max-width: 100% !important;
  overflow-x: auto !important;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}

.hide-at-mobile {
  @media (max-width: 1200px) {
    display: none;
  }
}

.MuiDrawer-paper {
  width: 33% !important;
  height: 100%;
  // top: 180px !important;
}

.MuiBackdrop-root {
  // top: 180px !important;
  height: 100% !important;
  // background-color: rgba(0, 0, 0, -0.5) !important;
}

@media (max-width: 1200px) {
  .MuiDrawer-paper {
    width: 100% !important;
    top: 0 !important;
    height: 100%;
  }
}

p {
  margin: 0 !important;
}

img {
  height: auto;
}
div[role="presentation"] {
  z-index: 18 !important;
}

.text-white {
  color: #fff !important;
}

.main-theme-colored {
  color: $nav-item-color;
}
.active-path {
  color: $nav-item-color !important;
}

a {
  & > *:not(.fit-content) {
    // width: 100%;
  }
}

.MuiAccordionSummary-content {
  margin: 0rem !important;
}

.MuiAccordionSummary-root {
  align-items: flex-start;
  min-height: fit-content !important;

  & * {
    align-self: flex-start;
  }
}

.MuiAccordionDetails-root {
  padding: 0 16px !important;
}

.pointer {
  cursor: pointer !important;
}

form {
  height: fit-content !important;
  padding: 0 !important;
  margin: 0 !important;
}

button.no-style {
  border: none !important;
  background-color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input.no-style {
  outline: none !important;
  border: none !important;

  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.actions-spans {
  & button {
    border: none;
    background-color: inherit;
  }

  & > div,
  button {
    cursor: pointer;
    font-size: 14px;
    font-weight: bolder;
    font-weight: 500;
    color: $banner-sub-title;

    &:hover {
      color: $nav-item-color;
    }
  }

  & .middle-line {
    min-width: 1px;
    min-height: 100%;
    background-color: $gray-border;
    margin: 0 1rem;
  }
}

.color-gray {
  color: $banner-sub-title;
}

.intercom-lightweight-app-launcher {
  // @media (max-width: 700px) {
  //   bottom: 75px !important;
  // }
}

.fill-at-mobile {
  @media (max-width: 700px) {
    width: 100% !important;
  }
}

img {
  // image-rendering: crisp-edges !important;
}

.MuiSkeleton-text {
  transform: scale(1) !important;
}

.rec-pagination {
  & button {
    margin: 0 7px !important;
  }

  & .cTIvzZ {
    background-color: inherit;
  }

  & .dVlYDw {
    position: relative !important;
  }
}

.dVlYDw {
  top: 0px !important;
}

.btiVaW {
  box-shadow: none !important;
  background-color: #fff !important;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.white {
  & * {
    color: #fff;
    fill: #fff;
  }
}

.ffzHKN {
  background-color: $nav-item-color !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
  }
}

.link-hover {
  &:hover {
    & * {
      color: $nav-item-color !important;
      fill: $nav-item-color !important;
    }
  }
}

.header-padding {
  padding: 0 0.5rem;
  @media (min-width: 992px) {
    padding: 0 3rem;
  }
}

.app-top-padding {
  padding-top: 12vh;
  @media (min-width: 700px) {
    padding-top: 19vh;
  }
  @media (min-width: 1920px) {
    padding-top: 15vh;
  }
}

.column-gap-md {
  // column-gap: 1rem;
}

.grid-4-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  & > div {
    width: 100% !important;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
}

.global-gap-md {
  & > *:not(:first-child) {
    margin: 0 1rem !important;
  }
  & > *:not(:last-child) {
    margin: 0 1rem !important;
  }
}

.grid-4-3-cols {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  & > div {
    width: 100% !important;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.scroll-x-auto {
  overflow-x: auto;
}

.grid-4-3-cols-md {
  @media (min-width: 900px) {
    overflow-x: hidden;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 2.5rem;
    & > div {
      width: 100% !important;
    }
  }
}

@media (max-width: 900px) {
  .one-third-mobile {
    display: grid !important;
    grid-column-gap: 0.7rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // display: flex !important;
    // // gap: 0.5rem;
    // & .dress-card  {
    //   margin: 0 7px;
    // }
  }
}

.ant-select-selector {
  border-color: inherit !important;
  box-shadow: inherit !important;
  border-radius: 8px !important;
  border: 1px solid #e4e6ef !important;
  font-size: 13px !important;
  padding: 2px 15px;
  min-height: 36px !important;
  margin-bottom: 6px !important;
}

.intercom-launcher-frame {
  height: 60px !important;
}

.ant-select-arrow {
  display: none !important;
}

.vw-3-fs {
  font-size: 70% !important;
  @media (min-width: 600px) {
    font-size: 80% !important;
  }
}
